/**
* @author ayou[ayoooo@petalmail.com]
* @date 2022/4/12 16:45
* @description
*   TheInputTool.vue of WeTrade
*/
<template>
<div class="input__tools df-middle bg-background">
  <Icon name="smile" class="mr-8 c-title" @click="useEmoji = !useEmoji" />
  <Donut v-if="processing" class="mr-8"/>
  <Icon name="picture" v-else @click="upload" class="mr-8" />
  <!-- <FileInput accept="image/*" class="mr-8 c-title" @input="useEmoji = false" @change="preview">
    <Icon name="picture" />
  </FileInput> -->
  <TheEmoji v-show="useEmoji" class="emoji-list" v-model:value="text" />
  <div class="flex-1 input__area br-lg df-middle">
    <img v-for="url in imgSrc" :key="url.slice(-8)" :src="url" alt="preview"
      class="v-middle" height="36" width="36" />
    <div class="flex-1">
      <input type="text" class="text__input" v-model="text" @keydown.delete="removePreview">
    </div>
  </div>
  <slot :before="before" :done="after" :collect="collect" />
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import TheEmoji from '@/modules/community/communities/components/TheEmoji.vue'
import Donut from 'common/loading/Donut.vue'
import { readImagesAsDataUrl } from 'essential/file'
import { defineComponent, shallowRef } from 'vue'
import { getImgBs64 } from '@/pages/customerService/cs'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheInputTool',
  components: { Icon, TheEmoji, Donut },
  setup () {
    const useEmoji = shallowRef(false)
    const text = shallowRef('')
    const imgSrc = shallowRef<string[]>([])
    const processing = shallowRef(false)

    const preview = (e: InputEvent) => {
      const target = e.currentTarget as HTMLInputElement
      if (target.files) {
        readImagesAsDataUrl(target.files).then(urls => imgSrc.value = R.pluck('data', urls))
      }
    }

    const upload = async () => {
      processing.value = true
      getImgBs64(false).then(res => {
        imgSrc.value = ['data:image/png;base64,' + res]
      }).finally(() => {
        processing.value = false
      })
    }

    const removePreview = () => {
      if (text.value === '') {
        /**
         * 因为目前仅支持单选 一删删所有 所以这里直接置空
         */
        imgSrc.value = []
      }
    }

    const collect = () => {
      return {
        text: text.value,
        img: imgSrc.value,
      }
    }

    return {
      text,
      useEmoji,
      imgSrc,
      processing,
      preview,
      upload,
      before () {
        useEmoji.value = false
      },
      after () {
        imgSrc.value = []
        text.value = ''
      },
      removePreview,
      collect,
    }
  },
})
</script>

<style scoped lang="scss">
.input__tools {
  position: relative;
  padding: 0 16px;
  height: 70px;
}

.emoji-list {
  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
}

.input__area {
  padding: 0 8px;
  border: 1px solid var(--color-border);
  background: var(--color-white);

  &:focus-within {
    border-color: transparent;
    outline: 2px solid var(--color-primary);
  }
}

.text__input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
}
</style>
