
import ServiceBusy from '@/pages/customerService/components/ServiceBusy.vue'
import TheChatService from '@/pages/customerService/components/TheChatService.vue'
import { CSRight, readCSRight } from '@/pages/customerService/cs'
import HoldOn from '@/provider/HoldOn.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'CustomerService',
  components: {
    TheChatService,
    ServiceBusy,
    PageWithHeader,
    HoldOn,
  },
  setup () {
    const service = shallowRef<CSRight | null>(null)

    readCSRight().then(resp => {
      service.value = resp
    })

    return {
      service,
    }
  },
})
