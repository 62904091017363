/**
* @author ayou[ayoooo@petalmail.com]
* @date 2022/4/12 19:58
* @description
*   Header.vue of WeTrade
*/
<template>
<div class="df-middle cs__header">
  <Pic src="user/Avatar" width="32" height="32" />
  <template v-if="config">
    <div class="flex-1 pl-10 c-title">
      {{config.staffName}}
    </div>
    <router-link to="/qa">
      <button class="cs__btn">
        <Icon name="help" sm clas="mr-8" />
        FAQ
      </button>
    </router-link>
<!--    <button class="cs__btn ml-10" @click="$emit('loadHistory')">-->
<!--      <Icon name="comment" sm clas="mr-8" />-->
<!--      Record-->
<!--    </button>-->
  </template>
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Header',
  components: { Icon, Pic },
  props: {
    config: {
      type: Object,
    },
  },
})
</script>

<style scoped>
.cs__header {
  height: 60px;
  padding: 0 16px;
}

.cs__btn {
  width: 80px;
  height: 24px;
  border: 1px solid var(--color-border);
  border-radius: 16px;
  font-size: 12px;
  color: var(--color-tip);
  background: var(--color-white);
}
</style>
