
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Header',
  components: { Icon, Pic },
  props: {
    config: {
      type: Object,
    },
  },
})
