
import { resolveCustomMessageBody } from '@/pages/customerService/cs'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCustomMessage',
  props: {
    msg: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return {
      body: resolveCustomMessageBody(props.msg),
    }
  },
})
