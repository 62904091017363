/**
* File: TheChatService.vue of WeTrade
* Author: 阿佑[ayooooo@petalmail.com]
* Date: 2023/10/9 15:29
*/
<template>
  <HoldOn :content="config" :height="100" class="flex-1 fill df-col">
    <NIMChat #="{send}" :config="config" :flush="flush" :use-room="false"
      @ready="readyToChat" :connect="handleConnect" :disconnect="handleDisconnect"
    >
      <TheChatableHeader :email="service.email" />
      <Header :config="serverConfig" @loadHistory="loadHistory" />
      <Line />
      <div ref="listEl" class="flex-1 scroll-y p-r px-16" style="scroll-behavior: auto">
        <!--历史消息-->
        <TheChatMessage v-for="msg in history" :key="msg.idClient" :msg="msg" :config="config" />
        <div v-if="messages.length" class="t-center time-divider c-tip py-8">
          <span class="px-16">Today</span>
        </div>
        <!--新消息-->
        <TheChatMessage v-for="msg in messages" :key="msg.idClient" :msg="msg" :config="config" />
        <span v-show="unreadCount !== 0" class="toast" @click="markRead">
            <Icon name="caret-down" sm />
            <t path="live_26" :args="{number: unreadCount}">{{ unreadCount }} new messages</t>
          </span>
      </div>
      <!--新消息提醒-->
      <TheInputTool v-if="chatReady" #="{ collect, before, done }">
        <Button
          :progress="sending" class="primary ml-10 br-lg" style="width: 88px;height: 40px"
          @click="comment(send, collect, before, done);"
        >
          Send
        </Button>
      </TheInputTool>
    </NIMChat>
  </HoldOn>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import Header from '@/pages/customerService/components/Header.vue'
import TheChatableHeader from '@/pages/customerService/components/TheChatableHeader.vue'
import TheChatMessage from '@/pages/customerService/components/TheChatMessage.vue'
import TheInputTool from '@/pages/customerService/components/TheInputTool.vue'
import { scrollToBottom, useChatConfig, useFlush, useSend } from '@/pages/customerService/cs'
import HoldOn from '@/provider/HoldOn.vue'
import { NIM, NIMMessage } from 'common/chat/chat.api'
import NIMChat from 'common/chat/NIMChat.vue'
import { computed, defineComponent, nextTick, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheChatService',
  components: {
    HoldOn,
    TheChatableHeader,
    Line,
    Header,
    Icon,
    Button,
    TheInputTool,
    TheChatMessage,
    NIMChat,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const { serverConfig, chatConfig } = useChatConfig()
    const chatReady = shallowRef(false)
    const listEl = shallowRef<HTMLElement | null>(null)
    const { history, messages, size, appendSize, flush: _flush } = useFlush()
    const to = computed(() => serverConfig.value?.staffAccid)
    const { send, sending } = useSend(to)
    const nim = shallowRef<NIM | null>(null)
    const unreadCount = shallowRef(0)

    const markRead = () => {
      unreadCount.value = 0
      scrollToBottom(listEl, 'smooth')
    }

    const flush = (msgs: Array<NIMMessage>) => {
      _flush(msgs)
      locateBottom(msgs)
    }

    const locateBottom = (msgs: NIMMessage[]) => {
      nextTick(() => {
        const el = listEl.value as HTMLElement
        if (el) {
          const notInBottom = el.scrollHeight - el.clientHeight > el.scrollTop
          const notInit = size.value !== 0
          if (notInBottom && notInit) {
            unreadCount.value += appendSize.value
            scrollToBottom(listEl, msgs.length < 3 ? 'smooth' : 'auto')
          }
        }
      })
    }

    const loadHistory = () => {
      if (!chatReady.value) return

      nim.value?.getHistoryMsgs({
        endTime: history[0]?.time ?? 0,
        scene: 'p2p',
        to: serverConfig.value?.staffAccid as string,
        done (error, d) {
          if (!error) _flush(d.msgs)
          locateBottom(d.msgs)
        },
      })
    }

    const readyToChat = (nimInstance: NIM) => {
      nim.value = nimInstance
      chatReady.value = true
      loadHistory()
    }

    // NIM 完成连接
    const handleConnect = () => {
      //
    }

    // NIM 断开连接
    const handleDisconnect = () => {
      //
    }

    return {
      serverConfig,
      config: chatConfig,
      history,
      messages,
      listEl,
      sending,
      unreadCount,
      chatReady,
      flush,
      comment: send,
      markRead,
      readyToChat,
      loadHistory,
      handleConnect,
      handleDisconnect,
    }
  },
})
</script>

<style scoped lang="scss">
.toast {
  position: absolute;
  left: 50%;
  bottom: 0;
  padding: 8px;
  margin-bottom: 12px;
  border-radius: 14px;
  color: var(--color-white);
  background: rgba(#181D24, 0.9);
  font-size: 12px;
  transform: translateX(-50%);
}

.time-divider {
  &::before,
  &::after {
    position: relative;
    bottom: 4px;
    display: inline-block;
    width: 34%;
    height: 1px;
    transform: scaleY(0.5);
    background: var(--color-tip);
    content: '';
  }
}
</style>
