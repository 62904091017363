
import Pic from '@/components/Pic.vue'
import { renderEmoji } from '@/modules/community/communities/community'
import { ChatConfig } from '@/modules/membership/member.api'
import TheCustomMessage from '@/pages/customerService/components/TheCustomMessage.vue'
import ImgPreviewer from '@/modules/home/components/items/ImgPreviewer.vue'
import { ExtendedNIMMessage, NIMMessageType } from 'common/chat/chat.api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheChatMessage',
  components: { TheCustomMessage, Pic, ImgPreviewer },
  props: {
    msg: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const config = props.config as ChatConfig
    const msg = props.msg as ExtendedNIMMessage
    const isSelf = config.accId === msg.from
    const isCustom = msg.type === NIMMessageType.CUSTOM
    const isImage = msg.type === NIMMessageType.IMAGE

    return {
      isCustom,
      isImage,
      isSelf,
      custom: msg.customObj,
      renderEmoji,
    }
  },
})
