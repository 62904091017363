
import Icon from '@/components/Icon.vue'
import { showAlert } from '@/components/popup/popup'
import { keymap } from '@/config'
import { localGet } from 'essential/store/localStore'
import { defineComponent } from 'vue'
import Clipboard from 'clipboard'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ServiceBusy',
  components: { Icon },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const { t } = useI18n()

    const copy = (text: string | null) => {
      if (!text) return

      const cb = new Clipboard('.copy', {
        text () {
          return text
        },
      })
      cb.on('success', () => {
        showAlert(t('toast_20'))
      })
      cb.on('error', () => {
        console.log('copy error')
      })
    }

    return {
      account: localGet(keymap.user.accountNum),
      copy,
    }
  },
})
