/**
 * @author ayou[ayoooo@petalmail.com]
 * @date 2022/4/12 15:18
 * @description
 *   cs.ts of WeTrade
 */
import { parseToJson } from '@/common/format'
import requestResult from '@/common/request/request'
import { keymap } from '@/config'
import {
  ExtendedNIMMessage,
  NIMChatConfig,
  NIMMessage,
  NIMMessageExtend,
  NIMMessageType,
  NIMSendObject,
} from 'common/chat/chat.api'
import { localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { computed, Ref, shallowReactive, shallowRef, unref } from 'vue'
import { updateImg } from '@/common/jsBridge.api'

/**
 appKey: "052a0e0d0f33a9a5d8c77b6580594a91"
 customerAccid: "wetradetest_1621320642679774"
 customerToken: "987879"
 staffAccid: "wetradetest_18611111111"
 staffName: "Test-001"
 */
export type CSConfig = {
  appKey: string;
  customerAccid: string;
  customerToken: string;
  staffAccid: string;
  staffName: string;
}

const readConfig = requestResult<CSConfig>('/api/netease/helpline/click/jsonp')

//     NEW_CUSTOM(0, "新客"), ORDINARY_CUSTOMERS(1, "普通客户"), HIGH_QUALITY_CUSTOMERS(2, "优质客户"), BIG_CUSTOM(3, "大客户");
enum CSLevel {
  NEWBIE,
  NORMAL,
  VIP,
  SVIP
}

export type CSRight = {
  customLever: CSLevel;
  email: string;
  liveChatFlg: boolean;
}

export const readCSRight = requestResult<CSRight>('/api/netease/helpline/custom/lever')

export const useChatConfig = () => {
  const serverConfig = shallowRef<CSConfig | null>(null)
  const chatConfig = shallowRef<NIMChatConfig | null>(null)
  const uuid = localGet(keymap.label.uuid)
  readConfig({
    uuid: uuid,
  })
    .then(v => {
      serverConfig.value = v

      chatConfig.value = {
        appKey: v.appKey,
        accId: v.customerAccid,
        token: v.customerToken,
      }
    })

  return { serverConfig, chatConfig }
}

export const scrollToBottom = (
  el?: HTMLElement | Ref<HTMLElement | null>,
  behavior: 'auto' | 'smooth' = 'auto',
) => {
  const target = unref(el)
  if (target) {
    target.scroll({
      top: target.scrollHeight,
      behavior: behavior,
    })
  }
}

const isEmpty = R.cond([
  [R.propEq('type', NIMMessageType.TEXT), R.propSatisfies(R.isEmpty, 'text')],
  [R.propEq('type', NIMMessageType.IMAGE), R.propSatisfies(R.isEmpty, 'file')],
  [R.propEq('type', NIMMessageType.CUSTOM), R.propSatisfies(R.isEmpty, 'content')],
  [R.T, R.F],
])

const extend = (msg: NIMMessage): ExtendedNIMMessage => {
  const extend: NIMMessageExtend = {
    customObj: parseToJson(msg.custom).json ?? {},
    contentObj: {},
  }

  if (msg.type === NIMMessageType.CUSTOM) {
    /**
     * 后端序列化的json格式有问题
     * 这里特殊处理
     */
    const content = msg.content.replace(/'/g, '')
    extend.contentObj = parseToJson(content).json ?? {}
  }

  return {
    ...msg,
    ...extend,
  }
}

export const useFlush = (msgs?: NIMMessage[]) => {
  const history = shallowReactive<Array<NIMMessage>>([])
  const messages = shallowReactive<Array<NIMMessage>>([])
  const size = computed(() => history.length + messages.length)
  const appendSize = shallowRef(0)

  const flush = (msgs: NIMMessage[]) => {
    const history_ = []
    const message_ = []
    const now = Date.now()
    const today = now - (now % (1000 * 60 * 60 * 24))

    for (let i = msgs.length - 1; i >= 0; i--) {
      const msg = msgs[i]
      if (isEmpty(msg)) continue

      const message = extend(msg)

      if (message.time > today) {
        message_.push(message)
      } else {
        history_.push(message)
      }
    }

    appendSize.value = message_.length
    const uniq = R.uniqWith(R.eqProps('idClient'))
    history.push(...history_)
    const tempHistory = JSON.parse(JSON.stringify(history)) as NIMMessage[]
    history.length = 0
    history.push(...uniq(tempHistory) as NIMMessage[])

    messages.push(...message_)
    const tempMsg = JSON.parse(JSON.stringify(messages)) as NIMMessage[]
    messages.length = 0
    messages.push(...uniq(tempMsg) as NIMMessage[])
  }

  if (msgs) flush(msgs)

  return { history, messages, size, appendSize, flush }
}

export const useSend = (to: Ref<string | undefined>) => {
  const sending = shallowRef(false)

  const send = (
    NIMSend: (type: NIMMessageType, data: NIMSendObject) => Promise<NIMMessage>,
    collect: () => { text: string, img: string[], custom: any },
    before?: () => void,
    done?: () => void,
  ) => {
    if (sending.value || !unref(to)) return

    const avatar = localGet(keymap.user.avatar)

    before?.()

    sending.value = true

    const message = collect()
    const progress = []
    const commonMessage = {
      scene: 'p2p',
      to: unref(to),
      custom: JSON.stringify({ avatar }),
    }

    if (message.img.length) {
      progress.push(new Promise((resolve, reject) => {
        NIMSend(NIMMessageType.IMAGE, {
          ...commonMessage,
          type: 'image',
          dataURL: message.img[0], // 单张上传
        })
          .then(resolve)
          .catch(reject)
      }))
    }

    if (message.text) {
      progress.push(new Promise((resolve, reject) => {
        NIMSend(NIMMessageType.TEXT, {
          ...commonMessage,
          text: message.text,
        })
          .then(resolve)
          .catch(reject)
      }))
    }

    if (message.custom) {
      progress.push(new Promise((resolve, reject) => {
        NIMSend(NIMMessageType.CUSTOM, {
          ...commonMessage,
          content: JSON.stringify(message.custom),
        })
          .then(resolve)
          .catch(reject)
      }))
    }

    Promise
      .all(progress)
      .then(() => {
        done?.()
      })
      .finally(() => {
        sending.value = false
      })
  }

  return { send, sending }
}

export const resolveCustomMessageBody = R.cond([
  [R.propEq('type', 5), R.path(['msg', 'msg'])],
  [R.T, R.always('unknown message type')],
])

export const getImgBs64 = async (multiple: boolean) => {
  const resp = await updateImg({ multiple }) as { pic: string }
  return resp.pic
}
