
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import Header from '@/pages/customerService/components/Header.vue'
import TheChatableHeader from '@/pages/customerService/components/TheChatableHeader.vue'
import TheChatMessage from '@/pages/customerService/components/TheChatMessage.vue'
import TheInputTool from '@/pages/customerService/components/TheInputTool.vue'
import { scrollToBottom, useChatConfig, useFlush, useSend } from '@/pages/customerService/cs'
import HoldOn from '@/provider/HoldOn.vue'
import { NIM, NIMMessage } from 'common/chat/chat.api'
import NIMChat from 'common/chat/NIMChat.vue'
import { computed, defineComponent, nextTick, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheChatService',
  components: {
    HoldOn,
    TheChatableHeader,
    Line,
    Header,
    Icon,
    Button,
    TheInputTool,
    TheChatMessage,
    NIMChat,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const { serverConfig, chatConfig } = useChatConfig()
    const chatReady = shallowRef(false)
    const listEl = shallowRef<HTMLElement | null>(null)
    const { history, messages, size, appendSize, flush: _flush } = useFlush()
    const to = computed(() => serverConfig.value?.staffAccid)
    const { send, sending } = useSend(to)
    const nim = shallowRef<NIM | null>(null)
    const unreadCount = shallowRef(0)

    const markRead = () => {
      unreadCount.value = 0
      scrollToBottom(listEl, 'smooth')
    }

    const flush = (msgs: Array<NIMMessage>) => {
      _flush(msgs)
      locateBottom(msgs)
    }

    const locateBottom = (msgs: NIMMessage[]) => {
      nextTick(() => {
        const el = listEl.value as HTMLElement
        if (el) {
          const notInBottom = el.scrollHeight - el.clientHeight > el.scrollTop
          const notInit = size.value !== 0
          if (notInBottom && notInit) {
            unreadCount.value += appendSize.value
            scrollToBottom(listEl, msgs.length < 3 ? 'smooth' : 'auto')
          }
        }
      })
    }

    const loadHistory = () => {
      if (!chatReady.value) return

      nim.value?.getHistoryMsgs({
        endTime: history[0]?.time ?? 0,
        scene: 'p2p',
        to: serverConfig.value?.staffAccid as string,
        done (error, d) {
          if (!error) _flush(d.msgs)
          locateBottom(d.msgs)
        },
      })
    }

    const readyToChat = (nimInstance: NIM) => {
      nim.value = nimInstance
      chatReady.value = true
      loadHistory()
    }

    // NIM 完成连接
    const handleConnect = () => {
      //
    }

    // NIM 断开连接
    const handleDisconnect = () => {
      //
    }

    return {
      serverConfig,
      config: chatConfig,
      history,
      messages,
      listEl,
      sending,
      unreadCount,
      chatReady,
      flush,
      comment: send,
      markRead,
      readyToChat,
      loadHistory,
      handleConnect,
      handleDisconnect,
    }
  },
})
