
import Icon from '@/components/Icon.vue'
import TheEmoji from '@/modules/community/communities/components/TheEmoji.vue'
import Donut from 'common/loading/Donut.vue'
import { readImagesAsDataUrl } from 'essential/file'
import { defineComponent, shallowRef } from 'vue'
import { getImgBs64 } from '@/pages/customerService/cs'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheInputTool',
  components: { Icon, TheEmoji, Donut },
  setup () {
    const useEmoji = shallowRef(false)
    const text = shallowRef('')
    const imgSrc = shallowRef<string[]>([])
    const processing = shallowRef(false)

    const preview = (e: InputEvent) => {
      const target = e.currentTarget as HTMLInputElement
      if (target.files) {
        readImagesAsDataUrl(target.files).then(urls => imgSrc.value = R.pluck('data', urls))
      }
    }

    const upload = async () => {
      processing.value = true
      getImgBs64(false).then(res => {
        imgSrc.value = ['data:image/png;base64,' + res]
      }).finally(() => {
        processing.value = false
      })
    }

    const removePreview = () => {
      if (text.value === '') {
        /**
         * 因为目前仅支持单选 一删删所有 所以这里直接置空
         */
        imgSrc.value = []
      }
    }

    const collect = () => {
      return {
        text: text.value,
        img: imgSrc.value,
      }
    }

    return {
      text,
      useEmoji,
      imgSrc,
      processing,
      preview,
      upload,
      before () {
        useEmoji.value = false
      },
      after () {
        imgSrc.value = []
        text.value = ''
      },
      removePreview,
      collect,
    }
  },
})
