/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/28
* @description
*   TheChatMessage.vue of WeTrade
*/
<template>
<div class="p-16 msg" :class="{rtl: isSelf}">
  <div class="avatar order">
    <Pic :src="custom.avatar" fallback="/img/user/Avatar@2x.png" height="32" width="32"
      class="round" />
  </div>
  <div class="order mx-16 pt-16" style="max-width: 70%">
    <div class="content t-left" :class="{self: isSelf}">
      <span class="bubble" />
      <TheCustomMessage v-if="isCustom" :msg="msg.contentObj" />
      <div v-else-if="isImage">
        <ImgPreviewer >
          <Pic :src="msg.file.url" :alt="msg.file.name" style="max-width: 150px" />
        </ImgPreviewer>
      </div>
      <div v-else class="c-text" style="word-break: break-all;" v-html="renderEmoji(msg.text)" />
    </div>
  </div>
</div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { renderEmoji } from '@/modules/community/communities/community'
import { ChatConfig } from '@/modules/membership/member.api'
import TheCustomMessage from '@/pages/customerService/components/TheCustomMessage.vue'
import ImgPreviewer from '@/modules/home/components/items/ImgPreviewer.vue'
import { ExtendedNIMMessage, NIMMessageType } from 'common/chat/chat.api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheChatMessage',
  components: { TheCustomMessage, Pic, ImgPreviewer },
  props: {
    msg: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const config = props.config as ChatConfig
    const msg = props.msg as ExtendedNIMMessage
    const isSelf = config.accId === msg.from
    const isCustom = msg.type === NIMMessageType.CUSTOM
    const isImage = msg.type === NIMMessageType.IMAGE

    return {
      isCustom,
      isImage,
      isSelf,
      custom: msg.customObj,
      renderEmoji,
    }
  },
})
</script>

<style scoped lang="scss">
.msg {
  overflow: hidden;
}

.avatar {
  display: inline-block;
  padding: 8px 0;

  img {
    vertical-align: middle;
  }
}

.content {
  margin: 8px 12px 0 12px;
  position: relative;
  border-radius: 8px;
  padding: 10px 20px;
  background: var(--color-light);

  &.self {
    background: var(--color-asst-light);

    .bubble::before,
    .bubble::after {
      border-color: var(--color-asst-light);
    }
  }
}

.bubble {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;

  &::before {
    content: "";
    position: absolute;
    top: -12px;
    right: -4px;
    height: 30px;
    border-left: 32px solid var(--color-light);
    background: var(--color-light);
    border-bottom-left-radius: 40px 30px;
  }

  &::after {
    content: "";
    position: absolute;
    top: -30px;
    right: -4px;
    width: 34px;
    height: 30px;
    background: white;
    border-bottom-left-radius: 20px 10px;
  }
}

.order {
  float: left;
}

.rtl {
  .order {
    float: right;
    text-align: right;
  }

  .bubble {
    left: unset;
    right: 0;
    transform: rotateY(180deg);
  }
}
</style>
