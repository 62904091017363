/**
* File: ServiceBusy.vue of WeTrade
* Author: 阿佑[ayooooo@petalmail.com]
* Date: 2023/10/8 17:45
*/
<template>
  <div class="px-32">
    <div class="t-center" style="margin-top: 96px">
      <img src="/img/service_busy@2x.png" alt="busy" width="257">
    </div>
    <t as="p" path="customerservice_4" class="mt-16">
      The customer service agent is busy. If you have any questions, please write an email to us, and remember to leave your UID number in the email.
    </t>
    <p class="mt-24">
      <t paht="customerservice_5">Your UID:</t> {{ account }}
      <Icon name="copy" :version="2" sm class="copy ml-16" @click="copy(account)" />
    </p>
    <p class="mt-12">
      <t path="customerservice_6">Email:</t> {{ service.email }}
      <Icon name="copy" :version="2" sm class="copy ml-16" @click="copy(service.email)" />
    </p>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { showAlert } from '@/components/popup/popup'
import { keymap } from '@/config'
import { localGet } from 'essential/store/localStore'
import { defineComponent } from 'vue'
import Clipboard from 'clipboard'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ServiceBusy',
  components: { Icon },
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const { t } = useI18n()

    const copy = (text: string | null) => {
      if (!text) return

      const cb = new Clipboard('.copy', {
        text () {
          return text
        },
      })
      cb.on('success', () => {
        showAlert(t('toast_20'))
      })
      cb.on('error', () => {
        console.log('copy error')
      })
    }

    return {
      account: localGet(keymap.user.accountNum),
      copy,
    }
  },
})
</script>
