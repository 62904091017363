/**
* File: TheChatableHeader.vue of WeTrade
* Author: 阿佑[ayooooo@petalmail.com]
* Date: 2023/10/8 17:00
*/
<template>
  <div class="row px-16 py-8 d-f">
    <div class="block flex-1 df-cross-center px-12">
      <div class="flex-1">UID:{{ account }}</div>
      <Icon name="copy" :version="2" sm class="copy" @click="copy(account)" />
    </div>
    <div class="block flex-1 df-cross-center px-12">
      <div class="flex-1">{{ email }}</div>
      <Icon name="copy" :version="2" sm class="copy" @click="copy(email)" />
    </div>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { showAlert } from '@/components/popup/popup'
import { keymap } from '@/config'
import { localGet } from 'essential/store/localStore'
import { defineComponent } from 'vue'
import Clipboard from 'clipboard'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'TheChatableHeader',
  components: { Icon },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  setup () {
    const { t } = useI18n()

    const copy = (text: string | null) => {
      if (!text) return

      const cb = new Clipboard('.copy', {
        text () {
          return text
        },
      })
      cb.on('success', () => {
        showAlert(t('toast_20'))
      })
      cb.on('error', () => {
        console.log('copy error')
      })
    }

    return {
      account: localGet(keymap.user.accountNum),
      copy,
    }
  },
})
</script>

<style scoped lang="scss">
.row {
  background: #E6EDFE;
}

.block {
  height: 40px;
  border-radius: 6px;
  background: var(--color-white);

  &:not(:first-child) {
    margin-left: 8px;
  }
}
</style>
