/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/28
 * @description
 *   chat.api.ts of WeTrade
 */
export type NIMChatConfig = {
  accId: string;
  appKey: string;
  token: string;
  roomId?: string;
}

export enum NIMMessageType {
  TEXT = 'text',
  IMAGE = 'image',
  NOTIFICATION = 'notification',
  CUSTOM = 'custom'
}

type NIMImageDescription = {
  name: string;
  size: number;
  md5: string;
  url: string;
  ext: string;
  w: number;
  h: number;
}

export type NIMMessage = {
  idClient: string;
  from: string;
  fromNick: string;
  type: NIMMessageType;
  time: number;
  content: string;
  custom: string;
  text?: string;
  file?: NIMImageDescription;
  isSystem?: boolean;
}

export type NIMMessageExtend = {
  contentObj: Data;
  customObj: Data;
}

export type ExtendedNIMMessage = NIMMessage & NIMMessageExtend

interface NIMMessageCommon {
  scene?: string;
  to?: string;
  custom?: string;

  done? (err: boolean, msg: NIMMessage): void;
}

export interface NIMTextMessageObject extends NIMMessageCommon {
  text: string;
}

export interface NIMImageMessageObject extends NIMMessageCommon {
  type: 'image';
  fileInput?: string;
  blob?: Blob;
  dataURL?: string;
}

export interface NIMCustomMessageObject extends NIMMessageCommon {
  content: string;
}


export type NIMSendObject = NIMTextMessageObject | NIMImageMessageObject | NIMCustomMessageObject

export interface NIMInitialOptions {
  debug: boolean;

  onmsgs (msgs: NIMMessage[]): void;

  onconnect (): void;

  ondisconnect (): void;

  onerror (): void;
}

export interface NIM {
  sendText (message: NIMTextMessageObject): void;

  sendFile (message: NIMImageMessageObject): void;

  sendCustomMsg (message: NIMCustomMessageObject): void;

  getHistoryMsgs (p: {
    scene: string;
    to: string;
    beginTime?: number;
    endTime?: number;
    reverse?: boolean;
    done: (error: boolean | null, d: { msgs: NIMMessage[] }) => void;
  }): void;

  getChatroomAddress (data: {
    chatroomId: string;
    done? (error: boolean, resp: {
      address: Array<string>
    }): void
  }): void

  disconnect (): void;

  isConnected (): boolean;

  connect (): void;
}

export interface NIMRoom {
  getHistoryMsgs (data: {
    // @todo correct type to Array of enum
    msgTypes?: Array<string>;
    done? (error: boolean, resp: {
      msgs: Array<NIMMessage>
    }): void
  }): void;

  sendText (sendTarget: NIMTextMessageObject): void

  sendFile (message: NIMImageMessageObject): void;

  sendCustomMsg (message: NIMCustomMessageObject): void;

  disconnect (): void;

  isConnected (): boolean;

  connect (): void;
}
