/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/13 14:59
* @description
*   TheCustomMessage.vue of WeTrade
*/
<template>
<div>{{body}}</div>
</template>

<script lang="ts">
import { resolveCustomMessageBody } from '@/pages/customerService/cs'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCustomMessage',
  props: {
    msg: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return {
      body: resolveCustomMessageBody(props.msg),
    }
  },
})
</script>

<style scoped>

</style>
